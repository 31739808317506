import { FC, useState } from "react";
import { useGetTheme } from "../../../hooks/useGetTheme";
import Modal from "../../Modal";

interface LoginFormInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginForm: FC<LoginFormInterface> = ({ show, setShow }) => {
    const theme = useGetTheme();

    // Fetch email and password payload
    // Point to new endpoint to generic kiosk url, e.g kiosk.fidelityportal/graphql
    // Return bearer token, kiosk specific url e

    return (
        <Modal
            show={show}
            setShow={setShow}
            onClose={()=> {
            }}
            width={420}
        >
            <div className="bg-white">
                <p className="font-bold text-4xl mb-10 text-center">Setup Kiosk</p>

                <form>
                    <div className="flex flex-col mb-5">
                        <label className="form-label">Email *</label>
                        <input type="email" className="form-input" placeholder="Enter your email address." required />
                    </div>

                    <div className="flex flex-col mb-10">
                        <label className="form-label">Password *</label>
                        <input type="password" className="form-input" required />
                    </div>

                    <button className="btn btn-black">Login</button>
                </form>
            </div>
        </Modal>
    )
};

export default LoginForm;
