import { cartInfo } from '../queries/cart';
import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.stratus21PaymentIntentionOrderTcp;
}

interface ApplyDiscountAPIInterface {
    cartId: string;
    applyPoints: boolean;
    voucherIDs: number[];
}

export async function applyDiscount(payload : ApplyDiscountAPIInterface) {
    return await fetcher(
        `
            mutation ApplyCartDiscount {
                stratus21PaymentIntentionOrderTcp(
                    cartId: "${payload.cartId}",
                    applyPoints: ${payload.applyPoints},
                    voucherIdentifierRoleId: ${JSON.stringify(payload.voucherIDs)}
                ) {
                    ${cartInfo}
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}