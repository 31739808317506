import { FC } from "react";
import ScanQr from "./ScanQr";
import Vouchers from "./Vouchers";
import Points from "./Points";

export const Loyalty: FC = () => {
    return (
        <>
            <Vouchers />
            <Points />
            <ScanQr />
        </>
    );
}

export default Loyalty;