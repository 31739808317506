import { Capacitor, registerPlugin } from "@capacitor/core";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppContext } from "../../../context/App";
import { scanQr } from "../../../api/scanQr";
import { useGetTheme } from "../../../hooks/useGetTheme";
import { useGetCart } from "../../../hooks/useGetCart";
import useQueryParameter from "../../../hooks/useQueryParameter";


interface KioskScannerInterface {
    addListener: Function
}

const scanner = registerPlugin('BarcodeScanner') as KioskScannerInterface;

export const ScanQr: FC = () => {
    const [scannedQR, setScannedQR] = useState<string>('');
    const theme = useGetTheme();
    const isNative = Capacitor.isNativePlatform();
    const cart = useGetCart();
    const queryClient = useQueryClient();

    const scannerInputEl = useRef<HTMLInputElement>(null);

    const {
        addError
    } = useAppContext();

    const scanQrApi = useMutation({
        mutationFn: scanQr,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {
                queryClient.invalidateQueries({
                    queryKey: ["cart"]
                });
            }

            addError('stratus21KioskLogin', data);
        },
        onError: (err) => {
            addError('stratus21KioskLogin', null, "Error stratus21KioskLogin failed");
        }
    });

    const handleDebugSendRequest = () => {
        const identifier = scannerInputEl.current?.value ?? '';
        const cartId = cart?.cart?.id ?? '';
    
        scanQrApi.mutate({
            identifier,
            cartId,
        });
    };

    useEffect(() => {
        if(isNative) {
            scanner.addListener('onScanData', (eventData: string) => {
                setScannedQR(eventData);
                scanQrApi.mutate({
                    identifier: eventData,
                    cartId: cart?.cart?.id ?? ''
                });
            });
        }
    }, [isNative]);

    console.log(scannedQR);
    const isLoyaltyUser = cart?.cart?.hasRegisteredTCP;

    return (
        <>
            <div 
                className="flex p-8 items-center mb-4"
                style={{
                    backgroundColor: theme?.secondaryColor
                }}
            >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-6">
                    <path d="M0 21.3333H21.3333V0H0V21.3333ZM5.33333 5.33333H16V16H5.33333V5.33333Z" fill="#020617"/>
                    <path d="M0 48H21.3333V26.6666H0V48ZM5.33333 32H16V42.6666H5.33333V32Z" fill="#020617"/>
                    <path d="M26.667 0V21.3333H48.0003V0H26.667ZM42.667 16H32.0003V5.33333H42.667V16Z" fill="#020617"/>
                    <path d="M48.0003 42.6666H42.667V48H48.0003V42.6666Z" fill="#020617"/>
                    <path d="M32.0003 26.6666H26.667V32H32.0003V26.6666Z" fill="#020617"/>
                    <path d="M37.3333 32H32V37.3333H37.3333V32Z" fill="#020617"/>
                    <path d="M32.0003 37.3334H26.667V42.6667H32.0003V37.3334Z" fill="#020617"/>
                    <path d="M37.3333 42.6666H32V48H37.3333V42.6666Z" fill="#020617"/>
                    <path d="M42.6663 37.3334H37.333V42.6667H42.6663V37.3334Z" fill="#020617"/>
                    <path d="M42.6663 26.6666H37.333V32H42.6663V26.6666Z" fill="#020617"/>
                    <path d="M48.0003 32H42.667V37.3333H48.0003V32Z" fill="#020617"/>
                </svg>

                <p className="text-[30px]">{isLoyaltyUser ? (
                    <>
                        Your loyalty card has been scanned.
                    </>
                    ) : (
                        <>
                            <strong>Got a loyalty card? Scan it now</strong> to claim your <strong>vouchers</strong> and <strong>points!</strong>
                        </>
                    )}</p>
            </div>

            {process.env.REACT_APP_DEBUG ? (
                <div className="flex flex-col fixed left-0 top-0 w-full bg-gray-400 z-20 p-5">
                    <p className="text-white mb-5 border-b border-white pb-3 text-lg">Dev Tools</p>

                    <div className="flex items-center">
                        <p className="text-base text-white mb-0 mr-10">QR Scanner Input</p>
                        <input type="text" ref={scannerInputEl} className=" p-2" value="8ca5878f2d7b456ba20ace53db41c4ca" />
                        <button onClick={handleDebugSendRequest} className="bg-white rounded-lg p-2 ml-10 text-base font-bold hover:opacity-90">Send Request</button>
                    </div>
                    
                </div>
            ) : null}
        </>
    )
}

export default ScanQr;