import { DojoConfig } from "../types/types";

export default async function fetchDojoTerminals(
    dojoConfig: DojoConfig, 
    headers: Headers, 
    status: string
  ) {
      const endpoint = `pac/terminals?status=${status}&currency=GBP`;
  
      const requestOptions: RequestInit = {
          method: 'GET',
          headers: headers,
      };
  
      try {
          const response = await fetch(`${dojoConfig.url}/${endpoint}`, requestOptions);
          const data = await response.json();
          console.log('Response:', data);
          return data;
      } catch (error) {
          console.error('Error fetching Dojo terminals:', error);
          return error;
      }
  }
  