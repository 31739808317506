import { FC, useState, useEffect, useRef } from "react";
import { useRestartKiosk } from "../../hooks/restartKioskSession";
import { useGetTheme } from "../../hooks/useGetTheme";
import Modal from "../Modal";

export const TimeoutModal: FC = () => {
    const theme = useGetTheme();
    const defaultTime = 30; // 30 seconds countdown
    const showModalIn = 60000 * 1; // 5 mins
    const [show, setShow] = useState(false);
    const [timeLeft, setTimeLeft] = useState(defaultTime);
    const { restartKiosk } = useRestartKiosk();

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startTimeoutToShowModal = () => {
        clearExistingTimeouts();
        timeoutRef.current = setTimeout(() => {
            setShow(true);
        }, showModalIn);
    };

    const clearExistingTimeouts = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const startCountdown = () => {
        clearExistingTimeouts(); // Clear any existing intervals to avoid duplicates
        intervalRef.current = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current!);
                    restartKiosk();
                    return defaultTime;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    const handleUserActivity = () => {
        if (!show) {
            clearExistingTimeouts();
            startTimeoutToShowModal();
        }
    };

    useEffect(() => {
        const events = ["click", "scroll", "keydown", "mousemove"];
        events.forEach((event) =>
            window.addEventListener(event, handleUserActivity)
        );

        return () => {
            events.forEach((event) =>
                window.removeEventListener(event, handleUserActivity)
            );
            clearExistingTimeouts();
        };
    }, [show]);

    useEffect(() => {
        if (show) {
            setTimeLeft(defaultTime);
            startCountdown();
        }
    }, [show]);

    useEffect(() => {
        startTimeoutToShowModal();
        return () => {
            clearExistingTimeouts();
        };
    }, []);

    const handleCloseModal = () => {
        clearExistingTimeouts();
        setShow(false);
        setTimeLeft(defaultTime);
        startTimeoutToShowModal();
    };

    if (!show) return null;

    return (
        <Modal show={true} setShow={setShow}>
            <p className="font-bold mb-6 text-center text-[29px]">
                Are you still ordering?
            </p>
            <p className="text-center mb-6">
                This session will automatically end in<br />
                <strong>{timeLeft}</strong> seconds if left inactive...
            </p>

            <div className="flex">
                <button
                    className="btn btn-small-spacing btn-danger mr-4 w-1/2"
                    onClick={restartKiosk}
                >
                    Cancel Order
                </button>

                <button
                    className="btn btn-small-spacing w-1/2 text-white"
                    style={{
                        backgroundColor: theme.secondaryColor,
                    }}
                    onClick={handleCloseModal}
                >
                    Yes
                </button>
            </div>
        </Modal>
    );
};

export default TimeoutModal;
