import ErrorHandlingModal from './components/ErrorHandlingModal';
import Loader from './components/Loader';
import { AppProvider } from './context/App';
import Providers from './providers';

import AdminScreens from './pages/AdminScreens';

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <Providers>
            {/* Screen is 900px wide by 1300px in height */}
            <div className='w-[900px] mx-auto relative h-[1600px]'>
                <AppProvider>
                    <>
                        <AdminScreens />

                        {children}
                        <ErrorHandlingModal />
                    </>
                </AppProvider>
            </div>

            <Loader />
        </Providers>
    );
}
