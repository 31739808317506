import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.stratus21KioskLogin;
}

interface ScanQrApiInterface {
    identifier: string,
    cartId: string
}

export async function scanQr(payload: ScanQrApiInterface) {
    return await fetcher(
        `
            mutation KioskLogin {
                stratus21KioskLogin(
                    loyalty_identifier: "${payload.identifier}",
                    cart_mask: "${payload.cartId}"
                ) {
                    success
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}