import { useState } from 'react';

import LoginForm from '../components/Modals/Admin/LoginForm';
import PinModal from '../components/Modals/Admin/PinModal';

function AdminScreens() {
    const [show, setShow] = useState(false);

    return (
        <>
            <button onClick={()=>{setShow(true)}} className='absolute left-0 top-0 w-[45px] h-[45px] z-10'></button>
            <LoginForm show={show} setShow={setShow} />
            <PinModal show={show} setShow={setShow} />
        </>
    )
}

export default AdminScreens;