import { FC, useState } from "react";
import { useGetTheme } from "../../../hooks/useGetTheme";
import Modal from "../../Modal";

interface PinModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PinModal: FC<PinModalInterface> = ({ show, setShow }) => {
    const theme = useGetTheme();

    const [pinCode, setPinCode] = useState('');

    function pushPinNumber(number: string) {
        if(pinCode.length < 4)
        setPinCode(pinCode + number);
    }

    function removePinNumber() {
        setPinCode(pinCode.slice(0, -1));
    }

    return (
        <Modal
            show={show}
            setShow={setShow}
            onClose={()=> {
            }}
            width={420}
        >
            <div className="bg-white">
                <p className="font-bold text-4xl mb-5 text-center">Enter Pin</p>
                {/* <p className="text-error mb-5">Incorrect Pin.</p> */}
                <input 
                    type="number" 
                    className="border border-[#CBD5E1] rounded-sm mb-5 w-full h-[100px] px-5 text-[60px] text-center font-bold outline-none"
                    value={pinCode}
                />

                <div className="flex mb-5">
                    <button className="pinNumber" onClick={() => pushPinNumber('1')}>1</button>
                    <button className="pinNumber mx-5" onClick={() => pushPinNumber('2')}>2</button>
                    <button className="pinNumber" onClick={() => pushPinNumber('3')}>3</button>
                </div>

                <div className="flex mb-5">
                    <button className="pinNumber " onClick={() => pushPinNumber('4')}>4</button>
                    <button className="pinNumber mx-5" onClick={() => pushPinNumber('5')}>5</button>
                    <button className="pinNumber" onClick={() => pushPinNumber('6')}>6</button>
                </div>
                
                <div className="flex mb-5">
                    <button className="pinNumber" onClick={() => pushPinNumber('7')}>7</button>
                    <button className="pinNumber mx-5" onClick={() => pushPinNumber('8')}>8</button>
                    <button className="pinNumber" onClick={() => pushPinNumber('9')}>9</button>
                </div>

                <div className="flex">
                    <button className="pinNumber bg-red-400" onClick={() => removePinNumber()} >
                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[44px]'>
                            <path d="M8.53341 25.3333L6.66675 23.4666L14.1334 16L6.66675 8.53329L8.53341 6.66663L16.0001 14.1333L23.4667 6.66663L25.3334 8.53329L17.8667 16L25.3334 23.4666L23.4667 25.3333L16.0001 17.8666L8.53341 25.3333Z" fill="white"/>
                        </svg>
                    </button>
                    <button className="pinNumber mx-5" onClick={() => pushPinNumber('0')}>0</button>
                    <button 
                        className="pinNumber text-center flex justify-center items-center" 
                        style={{
                            backgroundColor: theme?.secondaryColor ?? ''
                        }}
                    >
                        <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                            <path d="M38.75 2.33333V11.6667H9.1175L17.1725 3.29L14 0L0.5 14L14 28L17.1725 24.71L9.1175 16.3333H43.25V2.33333H38.75Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Modal>
    )
};

export default PinModal;
